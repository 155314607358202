<template>
  <div class="palawa" :class="{ 'hidden': !showPalawa }" @click="hidePalawa">
    <div class="paragraph">
      <p>Stories in Unshackled take place on lutruwita, the
        homeland of the palawa people. They lived and hunted
        on these lands and bravely defended them during the
        Black War. We pay our respects to the entire palawa
        community, their ancestors and future generations.
      </p>
      <button class="palawa-button"><h2>CONTINUE</h2></button>
    </div>
  </div>

  <div class="home" @click="enterWebsite">
    <img class="unshackled-logo" src="../assets/images/unshackled_logo_roar.png">
    <div class="paragraph">
      <p>Raucus, lyrical, joyous and occasionally terrifying.
        This is a digital and aural exploration of the Prison
        Without Walls, informed by the remarkable Tasmanian
        convict archives and performed by a cast of eminent
        storytellers, digital artists, songwriters and musicians.
      </p>
    </div>
    <button v-if="isLive" class="landing-button">
      <h1>EXPLORE</h1>
    </button>
    <img class="logo-line" src="../assets/images/logo_line_1.png">

  </div>

  <div class="background-container">
    <div class="background-box bg1"></div>
    <div class="background-box bg2"></div>
    <div class="background-box bg3"></div>
    <div class="background-box bg4"></div>
    <div class="background-box bg5"></div>
  </div>
</template>

<script>
export default {
  name: "LandingUnshackled",
  data() {
    return {
      showPalawa: true
    };
  },
  mounted() {
    this.$store.dispatch("getSettings");
  },
  computed: {
    titleSize() {
      return this.$store.getWidth
    },
    settings() {
      return this.$store.getters.getSettings;
    },
    isLive() {
      if (this.settings && this.settings.live) {
        return true;
      } else {
        return false;
      }
    },
    pageMessage() {
      if (this.settings) {
        return this.settings.pageMessage;
      } else {
        return "";
      }
    },
    firstNationSplash() {
      if (this.settings) {
        return this.settings.firstNationSplash;
      } else {
        return "";
      }
    },
  },
  methods: {
    hidePalawa() {
      this.showPalawa = false;
    },
    enterWebsite() {
      this.$router.push('/storylines');
    },
  },
};
</script>

<style scoped lang="scss">
.palawa {
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  z-index: 100;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.5s;
}
.palawa-button {
  margin-top: 3em;
  height: 3em;
  width: clamp(13em, 50%, 22em);
  h2 {
    padding-top: 4px;
  }
}
.hidden {
  opacity: 0;
  pointer-events: none;
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  margin: 3em 0;
}
.unshackled-logo {
  width: clamp(18em, 90vw, 40em);
  margin-bottom: 0.5em;
}
.paragraph {
  width: 90%;
  max-width: 30em;
  font-size: clamp(15px, 4vw, 20px);
  text-align: center;
  color: var(--white);
}
.landing-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.landing-button {
  width: clamp(13em, 50%, 22em);
  border-radius: 0.5em;
  padding: 6px 25px 0px;
  h1 {
    font-size: clamp(2em, 10vw, 3.5em);
  }
}
.logo-line {
  width: clamp(20em, 78vw, 35em);
}
.background-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -100;
}
.background-box {
  width: 100vw;
  max-width: var(--app-width);
  height: 30%;
  display: flex;
  background-size: auto 101%;
  background-position: center;
  opacity: 0.35;
}
.bg1 { background-image: url("../assets/images/landing_line_1.jpg");}
.bg2 { background-image: url("../assets/images/landing_line_2.jpg");}
.bg3 { background-image: url("../assets/images/landing_line_3.jpg");}
.bg4 { background-image: url("../assets/images/landing_line_4.jpg");}
.bg5 { background-image: url("../assets/images/landing_line_5.jpg");}
</style>