<template>
  <div v-if="message" class="panelBlock">
    <div class="infoPanel">
      <h1>{{ message.title }}</h1>
      <div class="messageBody" v-html="message.body"></div>
      <button @click="doButton(message.buttons[0].action)" class="back-button" style="margin: 0 auto; position: relative;">
        <h2 style="text-align: center;">{{ message.buttons[0].text }}</h2>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoPanel",
  computed: {
    message() {
      return this.$store.getters.getHelpMessage
    }
  },
  methods: {
    doButton(val) {
      switch(val) {
        case "close":
          this.doClose()
          break
        case "stories":
          this.$router.push("stories")
          this.doClose()
          break
        default:
          this.doClose()
          val()
      }
    },
    doClose() {
      this.$store.commit("setHelpMessage",null)
    }
  },
  watch:{}
};
</script>

<style scoped lang="scss">
.panelBlock {
  width: 100vw;
  max-width: 768px;
  height: 100vh;
  position: absolute;
  display: flex;
  top: 0px;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  background-color: var(--half-black);
}
.infoPanel {
  display: flex;
  flex-direction: column;
  color: var(--white);
  background-color: var(--indigo);
  border: 1px solid var(--white);
  padding: 2em;
  margin: 1em;
  border-radius: 0.5em;
  max-width: 450px;

  text-align: center;
  .messageBody {
    flex-grow: 1;
  }
}
</style>