<template>
  <div :class="'base ' + site">
    <navbar v-if="site == 'Reassign'"></navbar>
    <navbarUnshackled v-if="site == 'Unshackled'"></navbarUnshackled>
    <router-view />
    <InfoPanel></InfoPanel>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import NavbarUnshackled from "@/components/NavbarUnshackled.vue";
import iconCodes from '@/mixins/iconCodes'
import InfoPanel from '@/components/InfoPanel.vue'
export default {
  components: {
    Navbar,
    NavbarUnshackled,
    InfoPanel
  },
  mixins: [iconCodes],
  data() {
    return {
      showNav: true,
    };
  },
  mounted() {
    window.addEventListener('resize', this.appHeight)
    this.appHeight()
    this.$store.commit("setSearchVars", this.query)
    if (window.location.href.indexOf("reassign") > -1) {
      this.$store.dispatch("setSite", "Reassign")
      document.title = "REASSIGN";
    } else {
      this.$store.dispatch("setSite", "Unshackled")
      document.title = "UNSHACKLED-LAGS";
    }
  },
  computed: {
    site() {
      return this.$store.getters.getSite
    },
    query() {
      return window.location.search;
    },
    fullPath() {
      return this.$route.fullPath
    }
  },
  methods: {
    appHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', window.innerHeight + "px")
      this.$store.commit("setSize", { width: window.innerWidth, height: window.innerHeight })
    }
  },
  watch: {
    $route(to, from) {
      // console.log(to)
    },
    fullPath(val) {
      this.$store.commit("setSearchVars", val)
    },
    query(val) {
      this.$store.commit("setSearchVars", val)
    }
  }
};
// Windows / Mac
if (navigator.appVersion.indexOf("Win") != -1) {
  document.getElementsByTagName("body")[0].classList.add("win");
} else if (navigator.appVersion.indexOf("Mac") != -1) {
  document.getElementsByTagName("body")[0].classList.add("mac");
}
</script>

<style lang="scss">
@import './assets/unshackled-icon.css';
:root {
  --app-height: 100%;
  --app-width: 768px;
  max-width: var(--app-width);
  margin: 0 auto;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  font-weight: 400;
}

body {
  height: var(--app-height);
  max-width: var(--app-width);
  font-size: 14px;
  width: 100vw;
  background-size: 100%;
  align-content: center;
  overflow: hidden;
  --black: #000000;
  --half-black: #00000066;
  --transparent-black: #00000000;
  --red: #FF7755;
  --blue: #77AADD;
  --green: #77BB77;
  --indigo: #080F1A;
  --transparent-indigo: #080F1A00;
  --grey: #8899AA;
  --half-grey: #8899AA44;
  --light-grey: #BBC4D9;
  --white: #EEF0F8;
  --image: url(@/assets/images/reassign_texture.jpg);
  outline: 2px solid var(--half-grey);
  background-color: var(--indigo);
  background-image: var(--image);
}
#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-flow: column;
  height: 100%;
  color: var(--white);
}
.base {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-flow: column;
  height: 100%;
  color: var(--white);
}
b {
  font-weight: bold !important;
}
.a-dialog-text {
  color: var(--indigo);
}

// Font Links
@font-face {
  font-family: "Veneer";
  src: url(assets/fonts/veneer.ttf) format("truetype");
}
@font-face {
  font-family: "Spectral";
  src: url(assets/fonts/Spectral.woff2) format("woff2");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DinCondensed";
  src: url(assets/fonts/DinCondensedBold.woff2) format("woff2");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "unshackled-icon";
  src: url(assets/fonts/unshackled-icon.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
// Reassign Font Styles (Unshackled at end of document)
h1 {
  font-family: "Veneer", sans-serif;
  font-size: 2.8em;
  text-transform: uppercase;
}
h2 {
  font-family: "Veneer", sans-serif;
  font-size: 1.75em;
  line-height: 1.1em;
  text-transform: uppercase;
}
h3 {
  font-family: "Spectral", serif;
  font-size: 1.4em;
  letter-spacing: -0.025em;
}
h4 {
  font-family: "Spectral", serif;
  font-size: 1.2em;
  letter-spacing: -0.025em;
}
p {
  font-family: "Spectral", serif;
  font-size: 1.1em;
  letter-spacing: -0.035em;
  line-height: 1.35em;
}
a {
  text-decoration: none;
  color: var(--white);
}
mark {
  background-color: transparent;
  color: var(--grey);
}
.win .font-fix-h1 {
  line-height: 0.75em;
}
.win .font-fix-h2 {
  line-height: 0.875em;
  margin-bottom: 8px;
}
.win .font-fix-splash {
  line-height: 2em;
}

// Navigation Bar
.navbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-image: var(--image);
  background-size: 100%;
}
.filter-group {
  display: flex;
  margin: 1px 0;
  background-color: var(--half-black);
}
.filter {
  display: flex;
  justify-content: center;
  width: 100%;
  outline: 1px solid var(--white);
  margin-top: -1px;
  padding: 5px;
  line-height: 1.75em;
  transition: 0.1s;
}

// Container
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid var(--white);
}
.containerFullPage {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.textbox {
  flex-grow: 1;
  padding: 10px 15px;
  padding-right: 0;
  line-height: 1.5em;
  margin-top: 0.2em;
}
.storyHeader {
  display: flex;
  position: relative;
  height: auto;
  transition: height 10s;
  .titleBox {
    display: flex;
    position: relative;
    cursor: pointer;
    width: 100%;
    transition: margin-top 0.75s;
    z-index: 2;
  }
  #iconBox {
    opacity: 1;
    transition: opacity 0.75s;
  }
  .background {
    transition: height 0.75s;
  }
  .backgroundTint{
    opacity: 1;
    transition: opacity 0.75s;
  }
}

.storyBase {
  padding: 0 15px;
  p {margin-bottom: 12px;}
}
.storyBaseAssignee {
  padding: 0 15px;
  p {margin-bottom: 6px;}
}
.assignee {
  color: var(--grey);
  min-width: 80px;
  text-align: right;
  margin-right: 10px;
}

// Icons
.iconbox {
  display: flex;
  padding: 15px;
  div {
    display: flex;
    align-items: center;
  } 
}
.icon {
  width: 1.3em; height: 1.3em;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "unshackled-icon";
}
.iconBox {
  font-size: 1.4em;
  font-family: "unshackled-icon";
  margin-left: 1px;
}
.menupng {background-image: url(@/assets/icons/menu.png);}
.england {background-image: url(@/assets/icons/england.svg);}
.scotland {background-image: url(@/assets/icons/scotland.svg);}
.ireland {background-image: url(@/assets/icons/ireland.svg);}
.wales {background-image: url(@/assets/icons/wales.svg);}
.france {background-image: url(@/assets/icons/france.svg);}
.germany {background-image: url(@/assets/icons/germany.svg);}
.italy {background-image: url(@/assets/icons/italy.svg);}
.sweden {background-image: url(@/assets/icons/sweden.svg);}
.jersey {background-image: url(@/assets/icons/jersey.svg);}
.america {background-image: url(@/assets/icons/america.svg);}
.canada {background-image: url(@/assets/icons/canada.svg);}
.africa {background-image: url(@/assets/icons/africa.svg);}
.south-africa {background-image: url(@/assets/icons/south_africa.svg);}
.jamaica {background-image: url(@/assets/icons/jamaica.svg);}
.ceylon {background-image: url(@/assets/icons/sri_lanka.svg);}
.india {background-image: url(@/assets/icons/india.svg);}
.sea {background-image: url(@/assets/icons/sea.svg);}

// Buttons
.button-group {
  margin: 0 1em 1em;
}
button {
  border: none;
  border-radius: 0.2em;
  background-image: linear-gradient(to right, var(--white) -100%, var(--grey) 100%);
  padding: 5px 7px;
  width: 100%;
  margin: 7px 0;
  color: var(--indigo);
  cursor: pointer;
  filter: drop-shadow(0 5px 5px var(--black));
  transition: 0.1s;
  h4 {
    margin-top: 1px;
  }
  .icon {
    width: 1.5em; height: 1.5em;
    filter: invert(1);
  }
  .iconBox {
    font-size: 1.75em;
    margin-right: 0.375em;
  }  
}
button:hover {
  transform: translateY(1px);
  background-image: linear-gradient(to right, var(--white) 0%, var(--grey) 150%);
  filter: drop-shadow(0 0 0 var(--transparent-black));
}
.large-button {
  border-radius: 0.5em;
  padding: 10px 20px;
  margin-bottom: 25px;
  justify-content: center;
  width: 75%;
  h1 {
    margin-bottom: -0.3em;
  }
}
.large-button-container {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
}
.large-button-landing {
  margin-bottom: 120px;
  width: calc(13em + 10vw);
}
.playBtn{
  position: absolute;
  top: 0px; bottom: 0px; left: 0px; right: 0px;
  margin: auto;
  width: 40px;
  height: 50px;
  font-family: "unshackled-icon";
  font-size: 40px;
  opacity: 0.75;
  cursor: pointer;
}
.back-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 6em);
  max-width: 16em;
  height: 30px;
  margin: 2em auto;
  pointer-events: auto;
}


// Menu Overlay
.menuOverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  border-bottom: 1px solid var(--white);
  max-width: var(--app-width);
}
.overlay {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--app-height) - 4em);
  position: relative;
  .background, .background:before {
    width: 100%; height: 100%;
    position: absolute;
  }
  .background:before {
    content: "";
    background-image: linear-gradient(90deg, var(--indigo) -50%, var(--transparent-black) 200%);
    top: 0; left: 0;
  }
}

// Augmented Reality
.AR_Page {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0px;
  background-image: var(--image);
  background-color: var(--indigo);
}
.AR_Holder {
  position: relative;
  width: 100vw;
  height: 100%;
}
.AR_Controls {
  position: absolute;
  top: 0;
  margin: 1em;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.a-enter-ar-button, .a-enter-vr-button {
  display: none;
}
.AR_Camera {
  width: 100%;
  height: 100%;
}
.AR_Control {
  display: flex;
}
.AR_Control h2 {
  width: 4em;
}
.AR_Control button {
  width: 5em;
  margin: 0 0.5em;
  text-align: center;
}
.curved {
  border-radius: 100%;
}
.inspectCont {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.inspectImage {
  width: 1000px;
  height: 100%;
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 0px;
}
.marker {
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  border: solid 10px var(--red);
  background-color: var(--black);
  font-size: 1000px;
  text-align: center;
  background-image: none;
}
.AR_Info {
  position: absolute;
  bottom: 0px;
  background-color: var(--grey);
  width: 100%;
  height: 120px;
  z-index: 2;
}

// Unshackled-Only Styles
.Unshackled {
  h1 {
    font-family: "DinCondensed", sans-serif;
    font-size: 2.8em;
    text-transform: uppercase;
  }
  h2 {
    font-family: "DinCondensed", sans-serif;
    font-size: 1.75em;
    line-height: 1.05em;
    text-transform: uppercase;
  }
  h3, h4 {
    font-family: "DinCondensed", serif;
    font-size: 1.5em;
    letter-spacing: -0.01em;
  }
  p {
    font-family: "DinCondensed", serif;
    font-size: 1.2em;
    letter-spacing: -0.01em;
    line-height: 1.1em;
  }
}
</style>