<template>
<div class="iconbox">
  <div>
    <div v-for="(icon,i) in icons" :key="'i'+i" >
      <div class="iconBox" v-html="iconText(icon)"></div>
    </div>
  </div>
</div>  
</template>

<script>
import iconCodes from '@/mixins/iconCodes'
export default {
  name: "IconBox",
  props: {
    icons: { default: [] },
  },
  mixins: [iconCodes],
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {}
};
</script>