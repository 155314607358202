import { createRouter, createWebHistory } from 'vue-router'
import StoryView from '../views/StoryView.vue'
import Landing from '../views/Landing.vue'
import LandingUnshackled from '../views/LandingUnshackled.vue'
import Homepage from '../views/Homepage.vue'

const routes = [
  
  { path: '/stories',
    name: 'stories',
    component: StoryView
  },
  { path: '/storylines',
    name: 'storylines',
    component: StoryView
  },
 
  { path: '/songlines',
    name: 'songlines',
    component: () => import('../views/SonglinesView.vue')
  },
  { path: '/people',
    name: 'people',
    component: () => import('../views/People.vue')
  },
  { path: '/artists',
    name: 'artists',
    component: () => import('../views/Artist.vue')
  },
  { path: '/locations',
    name: 'location',
    component: () => import('../views/Locations.vue')
  },
  { path: '/map',
    name: 'map',
    component: () => import('../views/Map.vue')
  },
  { path: '/asgn',
    name: 'assignees',
    component: () => import('../views/Assignees.vue')
  },
  { path: '/connections',
    name: 'connections',
    component: () => import('../views/Connections.vue')
  },
  { path: '/m',
    name: 'menu',
    component: () => import('../views/Media.vue')
  },
  { path: '/data',
    name: 'data',
    component: () => import('../views/Data.vue')
  },
  { path: '/exhibition',
    beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://cpexhibition.web.app/")
    }
  }
]
if (window.location.href.indexOf("reassign") > -1) {
  routes.push({
    path: '/',
    name: 'landing',
    component: Landing
  })

  routes.push({
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  })
} else {
  // routes.push({
  //   path: '/',
  //   name: 'landing',
  //   component: LandingUnshackled
  // })
  routes.push({
    path: '/',
    name: 'landing',
    component: Homepage
  })
  routes.push({
    path: '/lags',
    name: 'lags',
    component: LandingUnshackled
  })
  routes.push({
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutUnshackled.vue')
  })
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router