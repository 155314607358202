<template>
  <div class="filter-group">
    <div v-for="(b, bi) in buttons" :key="'b' + bi" :class="'filter' + isActive(b)" @click="doFilter(b)">
      <div class="iconBox" v-html="iconText(b)"></div>
    </div>
  </div>
</template>

<script>
import iconCodes from "@/mixins/iconCodes";
export default {
  name: "FilterGroup",
  components: {},
  mixins: [iconCodes],
  props: {
    filterGroup: { default: "StoryFilter" },
    buttons: {
      default: ["video", "music", "pod", "ar", "prop", "food"],
    },
  },
  data() {
    return {
      filtered: [],
    };
  },
  mounted() {
    this.filtered = this.buttons.concat([]);
    this.$store.commit("set" + this.filterGroup, this.filtered);
  },
  computed: {},
  methods: {
    isActive(f) {
      if (this.filtered.length == this.buttons.length) {
        return " inActive";
      }
      if (this.filtered.includes(f)) {
        return " isActive";
      }
      return " inActive";
    },
    doFilter(val) {
      const index = this.filtered.indexOf(val);
      if (this.filtered.length == this.buttons.length) {
        this.filtered = [val];
      } else {
        if (this.filtered.includes(val) && index > -1) {
          this.filtered = this.buttons.concat([]);
        } else {
          this.filtered = [val];
        }
      }
      this.$store.commit("set" + this.filterGroup, this.filtered);
    },
  },
  watch: {},
};
</script>

<style scoped>
.isActive {
  background-image: linear-gradient(90deg, var(--white) 0%, var(--light-grey) 100%);
  color: var(--indigo);
}
</style>