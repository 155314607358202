<template>
  <div v-if="notHome" class="navbar">
    <div class="navbar-menu">
      <div class="polygon"></div>
      <h1 class="font-fix-h1" @click="routeTo('Home', '/')" style="cursor: pointer;"><mark>RE</mark>ASSIGN</h1>
      <button class="navbar-button" @click="showMenu = !showMenu">
        <h2 class="font-fix-h2">{{ pageName }}</h2>
        <div class="icon menupng"></div>
      </button>
    </div>
    <expand>
      <div v-if="showMenu" class="menuOverlay" style="z-index: 10; position: absolute; height: calc(100vh - 4em);">
        <menu-overlay :hideMenu="hideMenu"></menu-overlay>
      </div>
    </expand>
  </div>
</template>

<script>
import Expand from "@/components/Expand.vue";
import MenuOverlay from "@/components/MenuOverlay.vue";
export default {
  name: "Navbar",
  components: {
    Expand,
    MenuOverlay,
  },
  props: {},
  data() {
    return {
      showMenu: false,
      pageName: "",
    };
  },
  mounted() {
    this.pageName = this.$route.name;
  },
  computed: {
    page() {
      return this.$route.name;
    },
    notHome() {
      return this.$route.path != "/";
    },
  },
  methods: {
    routeTo(name, path) {
      this.hideMenu();
      this.$router.push(path);
    },
    hideMenu(){
      this.showMenu=false
    }
  },
   watch: {
    page(val) {
      this.pageName = val;
      this.showMenu = false;
      var query = window.location.search;
      this.$store.commit("setSearchVars",query)
    }
   }
};
</script>

<style scoped lang="scss">
.navbar-menu {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 0;
  border-bottom: 1px solid var(--white);
  position: relative;
}
.navbar-button {
  width: auto;
  height: 30px;
  display: flex;
}
.polygon {
  position: absolute;
  bottom: 0;
  height: 65px; width: 100%;
  background-color: var(--black);
  opacity: 0.5;
  clip-path: polygon(165px 100%, 195px 0%, 0% 0%, 0% 100%);
  margin-left: -15px;
}
h1 {
  line-height: 1em;
  padding-top: 0.15em;
  z-index: 5;
}
</style>