<template>
  <div v-if="notHome" class="navbar">
    <div class="navbar-menu">
      <img class="navbar-logo" @click="routeTo('Home', '/')" src="../assets/images/unshackled_logo.png">
      <div class="navbar-button" @click="showMenu = !showMenu">
        <h2 style="padding-top: 7px;">{{ pageName }}</h2>
        <img style="width: 1.5em; margin-left: 3px" src="../assets/icons/menu.png">
      </div>
    </div>
    <expand>
      <div v-if="showMenu" class="menuOverlay" style="z-index: 10; position: absolute; height: calc(100vh - 4em);">
        <menu-overlay-unshackled :hideMenu="hideMenu"></menu-overlay-unshackled>
      </div>
    </expand>
  </div>
</template>

<script>
import Expand from "@/components/Expand.vue";
import MenuOverlayUnshackled from "@/components/MenuOverlayUnshackled.vue";
export default {
  name: "NavbarUnshackled",
  components: {
    Expand,
    MenuOverlayUnshackled,
  },
  props: {},
  data() {
    return {
      showMenu: false,
      pageName: "",
    };
  },
  mounted() {
    this.pageName = this.$route.name;
  },
  computed: {
    page() {
      return this.$route.name;
    },
    notHome() {
      return this.$route.path != "/" && this.$route.path != "/lags" ;
    },
  },
  methods: {
    routeTo(name, path) {
      this.hideMenu();
      this.$router.push(path);
    },
    hideMenu(){
      this.showMenu=false
    }
  },
   watch: {
    page(val) {
      this.pageName = val;
      this.showMenu = false;
      var query = window.location.search;
      this.$store.commit("setSearchVars",query)
    }
   }
};
</script>

<style scoped lang="scss">
.navbar-menu {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--white);
}
.navbar-logo {
  width: 170px;
  height: 100%;
  padding: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
.navbar-button {
  display: flex;
  align-items: center;
  padding-right: 1.25em;
  cursor: pointer;
}
</style>