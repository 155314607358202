<template>
  <div class="container">
    <div :class="'storyHeader ' + viewClass" @click="doOpen(!expanded)">
      <div class="background-container">
        <h2 class="icon-cross"></h2>
        <div class="background" :style="'background-image: url(' + backgroundImage + ');'">
          <div class="background-tint"></div>
        </div>
      </div>
      <div class="title">
        <div class="title-text">
          <h3>{{ title }}</h3>
          <p v-if="cont">{{ subtitle }}</p>
          <div style="display: flex;">
            <p v-if="cont.distanceText">{{ distanceText }} Away · </p>
            <p v-if="cont.address">{{ cont.address }}</p>
          </div>
          <p v-if="distanceText && !cont.address">{{ distanceText }} to Marker</p>
        </div>
        <icon-box id="iconBox" :icons="icons"></icon-box>
      </div>
    </div>

    <Expand>
      <!-- Expanded -->
      <div class="expanded-content" v-if="expanded">

        <div v-if="cont.address" class="desktop-mobile">
          <div style="display: flex; flex-direction: row; width: 390px">
            <div class="hours" style="text-align: right; padding: 0 1em;">
              <p>Monday:</p>
              <p>Tuesday:</p>
              <p>Wednesday:</p>
              <p>Thursday:</p>
              <p>Friday:</p>
              <p>Saturday:</p>
              <p>Sunday:</p>
            </div>
            <div class="hours" style="text-align: left;">
              <p><span v-if="cont.hoursMon">{{ cont.hoursMon }}</span><span v-else style="color: var(--red);">Closed</span></p>
              <p><span v-if="cont.hoursTue">{{ cont.hoursTue }}</span><span v-else style="color: var(--red);">Closed</span></p>
              <p><span v-if="cont.hoursWed">{{ cont.hoursWed }}</span><span v-else style="color: var(--red);">Closed</span></p>
              <p><span v-if="cont.hoursThu">{{ cont.hoursThu }}</span><span v-else style="color: var(--red);">Closed</span></p>
              <p><span v-if="cont.hoursFri">{{ cont.hoursFri }}</span><span v-else style="color: var(--red);">Closed</span></p>
              <p><span v-if="cont.hoursSat">{{ cont.hoursSat }}</span><span v-else style="color: var(--red);">Closed</span></p>
              <p><span v-if="cont.hoursSun">{{ cont.hoursSun }}</span><span v-else style="color: var(--red);">Closed</span></p>
            </div>
          </div>
          <div v-if="isAdmin" style="display: flex; flex-direction: row; width: 100%;">
            <div class="button-group" style="width: 100%;">
              <h4 v-if="cont.entryFee" style="color: var(--white)">Entry Fee: {{ cont.entryFee }}</h4>
              <button style="display: flex;">
                <div class="iconBox" v-html="websiteIconText"></div>
                <h4 style="text-align: left;" href="{{ cont.website }}">{{ cont.title }} Website</h4>
                <h2 style="position: absolute; right: 0; opacity: 0.1; font-size: 3.7em; line-height: 0.9em; top: 0; letter-spacing: -1px;">WEBSITE</h2>
              </button>
              <button style="display: flex;">
                <div class="iconBox" v-html="callIconText"></div>
                <h4 style="text-align: left;" href="{{ cont.phone }}">{{ cont.phone }}</h4>
                <h2 style="position: absolute; right: 0; opacity: 0.1; font-size: 3.7em; line-height: 0.9em; top: 0; letter-spacing: -1px;">CONTACT</h2>
              </button>
            </div>
          </div>
        </div>

        <p class="description" v-if="cont.description">{{ cont.description }}</p>
        <div class="button-group">
          <div v-for="(c, i) in content" :key="'i' + i" class="contentEntry">
            <button @click="showMedia(c)" style="display: flex; position: relative;">
              <div class="iconBox" v-html="iconText(c.type)"></div>
              <h4 style="text-align: left;">{{ c.title }}</h4>
              <h2 style="position: absolute; right: 0; opacity: 0.1; font-size: 3.7em; line-height: 0.9em; top: 0; letter-spacing: -1px;">{{ nameLookup(c.type) }}</h2>
            </button>
          </div>
        </div>
      </div>
    </Expand>

  </div>
</template>

<script>
import IconBox from "@/components/IconBox.vue";
import Expand from "@/components/Expand.vue"
import iconCodes from '@/mixins/iconCodes'
import gps from '@/mixins/gps'
export default {
  name: "StoryBox",
  components: {
    IconBox,
    Expand
  },
  mixins: [iconCodes, gps],
  props: {
    title: String,
    subtitle: String,
    open: { default: false },
    cont: Object,
    viewType: { default: "stories" }
  },
  data() {
    return {
      expanded: false,
    };
  },
  mounted() {
    this.expanded = this.open
  },
  computed: {
    site() {
      return this.$store.getters.getSite
    },
    searchVars() {
      return this.$store.getters.getSearchVars;
    },
    isAdmin() {
      if (this.searchVars && this.searchVars.split("&").length > 1) {
        return this.searchVars.split("&")[1] == "t"
      }
      return false
    },
    imagePath() {
      return this.$store.getters.getFirebasePath + "images/"
    },
    viewClass() {
      if (this.expanded) {
        return "expanded";
      } else {
        return "compact";
      }
    },
    content() {
      if (this.cont) {
        return this.cont.content;
      }
      return this.cont;
    },
    icons() {
      let icons = [];
      if (this.content) {
        for (var c in this.content) {
          icons.push(this.content[c].type);
        }
      }
      return icons;
    },
    backgroundImage() {
      if (this.cont) {
        if (this.cont.image.indexOf("http") > -1) {
          return this.cont.image;
        } else {
          return this.imagePath + this.cont.image
        }
      }
      return null;
    },
    distance() {
      if (this.cont && this.cont.gps && this.getUserLocation()) {
        return this.distanceFromGPS(this.getUserLocation(), this.gpsFromString(this.cont.gps))
      } else {
        return null
      }
    },
    distanceText() {
      if (this.distance) {
        return this.distanceString(this.distance)
      }
      return null
    }
  },
  methods: {
    doOpen(val) {
      let testing = ""
      if (this.isAdmin) {
        testing = "&t"
      }
      if (!this.expanded || val) {
        this.expanded = true
        if (this.viewType != "about") {
          if (this.site == "Unshackled" && this.viewType == "stories") {
            this.$router.push("storylines?" + this.cont.id + testing)
          } else {
            this.$router.push(this.viewType + "?" + this.cont.id + testing)
          }
        }
      } else {
        this.expanded = false
        if (this.viewType != "about") {
          if (this.site == "Unshackled" && this.viewType == "stories") {
            this.$router.push("storylines")
          } else {
            this.$router.push(this.viewType)
          }
        }
      }
    },
    showMedia(media) {
      if (media.id.indexOf("asgn") > -1) {
        this.$router.push(media.id)
      } else {
        this.$router.push("m?" + media.id + "&s")
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  position: relative;
  height: auto;
  overflow-y: hidden;
}
.container:hover {
  .title-text {
    color: var(--full-white);
  }
  .background {
    filter: brightness(1.25);
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  transition: margin-top 1s;
  cursor: pointer;
}
.title-text {
  width: 25em;
  flex-grow: 1;
  padding: 0.75em 1em 0.75em;
  transition: 0.25s;
}
.description {
  padding: 0 0.85em 1em;
}
.background-container {
  position: absolute;
  width: 100%;
  height: 10em;
  z-index: -1;
  cursor: pointer;
}
.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: filter 0.25s, height 1s;
}
.background-tint {
  height: 100%;
  background-image: linear-gradient(to right, var(--indigo) 10%, var(--transparent-indigo) 150%);
  transition: 1s;
}
.icon-cross {
  position: absolute;
  right: 0;
  color: var(--white);
  opacity: 0;
  margin: 0.5em 0.3em;
  z-index: 100;
  filter: drop-shadow(0 0 0.25em var(--black));
  transition: 1s;
}
.expanded {
  .title {
    margin-top: 10em;
  }
  .icon-cross {
    opacity: 1;
  }
  .background-tint {
    opacity: 0;
  }
}
.expanded-content {
  .title:hover {
    transition: 0.25s;
    .image {
      filter: brightness(1.2);
      transform: scale(1.05);
    }
    .title-text {
      color: var(--full-white);
    }
    .icon-forward {
      color: var(--white) !important;
    }
  }
}

.hours {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
}
.hours p {
  margin-bottom: 0em;
}
.desktop-mobile {
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .desktop-mobile {
    flex-direction: column;
  }
}
</style>