export default {
   data() {
     return {};
   },
   mounted() {},
   computed:{},
   methods: {
      mediaType(mediaType) {     
         if ( mediaType == "podcast") {
            mediaType = "pod"
         } else if ( mediaType == "drone" || mediaType == "viewpoint" || mediaType == "object" || mediaType == "window" || mediaType == "windowAR" || mediaType == "assignees") {
            mediaType = "ar"
         } else if ( mediaType == "examine") {
            mediaType = "ar"
         } else if ( mediaType == "videoAR") {
            mediaType = "ar"
         } else if ( mediaType == "song") {
            mediaType = "music"
         }
         return mediaType
      }
   },
   beforeDestroy: function () {},
   watch: {} 
}