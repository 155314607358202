<template>
  <div class="home" style="overflow-y: auto; height: 100%; max-width: var(--app-width);">
    <div style="flex-grow: 1.75"></div>
    <div class="heading">
      <h1><mark>RE</mark>ASSIGN</h1>
      <h2 class="font-fix-splash">TRUE TALES FROM THE GRASSY WOODLANDS</h2>
    </div>
    <div style="flex-grow: 1.1; max-height: 30px"></div>
    <p class="paragraph">
      The stories in the Reassign Project take place on lutruwita, the homeland of the palawa people.
      They lived and hunted on these lands and bravely defended them during the Black War.
      We pay our respects to the entire palawa community, their ancestors and future generations.
    </p>
    <div style="flex-grow: 0.9"></div>
    <button class="large-button large-button-landing">
      <router-link to="/stories" style="color: var(--indigo)">
        <h1>EXPLORE</h1>
      </router-link>
    </button>
    <div class="footer">
      <div class="logo">
        <div class="logo-box nthtas"></div>
        <div class="logo-box council"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Landing",
  mounted() {
    this.$store.dispatch("getSettings");
  },
  computed: {
    titleSize() {
      return this.$store.getWidth
    },
    settings() {
      return this.$store.getters.getSettings;
    }
  }
};
</script>

<style scoped lang="scss">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.heading {
  h1 {
    font-size: clamp(95px, 20vw, 182px);
    line-height: 0.6em;
    text-align: center;
  }
  h2 {
    font-size: clamp(21px, 4.4vw, 40px);
    text-align: center;
    background-color: var(--white);
    background-image: linear-gradient(90deg, var(--white) 0%, var(--grey) 125%);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
}
.paragraph {
  width: 90%;
  max-width: 30em;
  font-size: clamp(15px, 1.8vw, 20px);
  text-align: center;
  color: var(--grey);
}
.footer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: -1;
  height: 50px;
  width: 100%;
  max-width: var(--app-width);
  background-image: linear-gradient(90deg, var(--grey) 0%, var(--light-grey) 45%, var(--light-grey) 55%, var(--grey) 100%);
}
.logo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.logo-box {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.council {
  background-image: url(@/assets/images/logo_council.png);
  width: 100px;
  height: 25px;
}
.nthtas {
  background-image: url(@/assets/images/logo_nthtas.png);
  width: 60px;
  height: 25px;
}
</style>