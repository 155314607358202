import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'mind-ar/dist/mindar-image.prod.js';
import 'aframe'
import 'mind-ar/dist/mindar-image-aframe.prod.js';


 const app=createApp(App).use(store).use(router)
 app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('a-')
}
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('ion-')
}

app.mount('#app')


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  authDomain: "unshackled-roar.firebaseapp.com",
  projectId: "unshackled-roar",
  storageBucket: "unshackled-roar.appspot.com",
  messagingSenderId: "365542769849",
  appId: "1:365542769849:web:09bb6b8a9217a487788768",
  measurementId: "G-GYTJ5CYYMW"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
