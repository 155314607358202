<template>
  <div class="containerFullPage">
    <filter-group :buttons="filterButtons" :filterGroup="'StoryFilter'"></filter-group>
    <div id="contHolder" style="overflow-y: auto; height: 100%;">
      <story-box v-for="(cont,c) in content" :key="'c'+c" :id="'sbox'+cont.id" :ref="'sbox'+cont.id" :title="cont.title" :cont="cont"></story-box>
    </div>
  </div>
</template>

<script>
import FilterGroup from "@/components/FilterGroup.vue";
import StoryBox from "@/components/StoryBox.vue";
import media from "@/mixins/media.js";
export default {
  name: "StoryView",
  components: {
    StoryBox,
    FilterGroup,
  },
  mixins: [media],
  data() {
    return {
      filteredCont: [],
    };
  },
  mounted() {
    if (!this.firebaseData) {
      this.$store.dispatch("getContent");
    }
    // if(this.content) {
    // this.filteredCont=this.content
    // }
  },
  computed: {
    site() {
      return this.$store.getters.getSite
    },
    filterButtons(){
      if(this.site=="Unshackled"){
        return ['video','music','pod','interview']
      }
      return ['video','music','pod','ar']
    },
    searchVars() {
      return this.$store.getters.getSearchVars;
    },
    storyFilter() {
      return this.$store.getters.getStoryFilter;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    firebaseData() {
      return this.$store.getters.getContent;
    },
    content() {
      let content = [];
      let cont;
      let valid = true;
      let media;
      let mediaType;
      // filter content here
      for (var d in this.firebaseData) {
        valid = true;
        cont = this.firebaseData[d];
        if (cont.type != "story" || cont.mapIcon == "town") {
          valid = false;
        } else {
          if (cont.content && cont.content.length > 0) {
            media = cont.content;
            valid = false;
            for (var m in media) {
              mediaType = this.mediaType(media[m].type);
              if (this.storyFilter.includes(mediaType)) {
                valid = true;
              }
              if (this.storyFilter.includes("person") && media[m].type=="connection"){
                valid = true;
              }
            }
            if (this.storyFilter.includes("person") && cont.people) {
                valid = true;
              }
          }else{
            if (this.storyFilter.length==1){
               valid = false;
            }
          }
        }
        if (valid) {
          content.push(cont);
        }
      }
      content.sort(this.sortIndex);
      return content;
    },
  },
  methods: {
    sortIndex(itemA, itemB) {
      let A = parseFloat(itemA.index, 10);
      let B = parseFloat(itemB.index, 10);
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    },
    openBox() {
      let url = this.searchVars.split("?");
      if (url.length = 2 ) {
       
        let id = url[1];
        this.$nextTick(() => {
      
           if(this.$refs["sbox" + id]){
            this.$refs["sbox" + id][0].doOpen(true);
            this.scrollTo("sbox" + id);
           }
         
          
        });
      }
    },
    scrollTo(id) {
      const position = Math.max(0, document.getElementById(id).offsetTop - 100);
      // smooth scroll
      document
        .getElementById("contHolder")
        .scrollTo({ top: position, behavior: "smooth" });
    },
  },
  watch: {
    content() {
      setTimeout(this.openBox, 1000);
      //this.openBox()
    },
    storyFilter(val) {},
    searchVars() {
      this.openBox();
    },
  },
};
</script>