
export default {
    data() {
      return {
        localPos:null
      };
    },
    mounted() {
      
    },
    computed:{
        
    },
    methods: {
        getUserLocation(){
            if(!this.localPos){
                this.geolocate()
            }
            return this.localPos
        },
        geolocate(){    
            let loc =null
            navigator.geolocation.getCurrentPosition(position => {
            loc = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            this.localPos=loc
              
                //this.destinations.push({value:loc.lat+","+loc.lng ,label:"Your Location"})
                
                setTimeout(this.showLocation,2000)
            }); 
        },
     gpsFromString(coords){
        let pos=coords.split(",")
        return  {lat:parseFloat(pos[0]),lng:parseFloat(pos[1])}
      },
      distanceFromGPS(loc1,loc2){
        //console.log(loc1,loc2)
        let lat1=loc1.lat
        let lat2=loc2.lat
        let lon1=loc1.lng
        let lon2=loc2.lng
        
          const R = 6371e3; // metres
            const φ1 = lat1 * Math.PI/180; // φ, λ in radians
            const φ2 = lat2 * Math.PI/180;
            const Δφ = (lat2-lat1) * Math.PI/180;
            const Δλ = (lon2-lon1) * Math.PI/180;
  
            const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                      Math.cos(φ1) * Math.cos(φ2) *
                      Math.sin(Δλ/2) * Math.sin(Δλ/2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  
            const d = R * c; // in metres
            
           
            return d
        },
        distanceString(d){
            let dist
            if(d<1000){
                dist=d+" meters"
             }else  if(d<10000){
                dist=Math.floor(d/100)/10+" km"    
             }else{
                dist=Math.floor(d/1000)+" km"
             }
             
             return dist
        }
    },
    beforeDestroy: function () {
    
    },
    watch:{
      localPos(val){
          this.$store.commit('setUserLocation',val)
      }
    }
    
  }