<template>

<!--
MAP
FIND UNSHACKLED STORIES, LAGS AND SONGS 

ABOUT
MORE ABOUT THE VANDEMONIAN LAGS

-->
  <div class="overlay">
    <!-- STORYLINES -->
    <div class="container" @click="routeTo('Storylines', '/storylines')" style="height: 100%;">
      <div class="overlay-button">
        <h1>STORYLINES</h1>
        <div style="width: 0.5em"></div>
        <h2>TRUE TALES FROM THE<br>PRISON WITHOUT WALLS</h2>
      </div>
      <div class="background menu1"></div>
    </div>
    <!-- PEOPLE -->
    <div class="container" @click="routeTo('People', '/people')" style="height: 100%;">
      <div class="overlay-button">
        <h1>PEOPLE</h1>
        <div style="width: 0.5em"></div>
        <h2>A ROGUE’S GALLERY<br>OF VANDEMONIANS</h2>
      </div>
      <div class="background menu2"></div>
    </div>
    <!-- SONGLINES -->
    <div class="container" @click="routeTo('Songlines', '/songlines')" style="height: 100%;">
      <div class="overlay-button">
        <h1>SONGLINES</h1>
        <div style="width: 0.5em"></div>
        <h2>SONGS FROM THE<br>VANDEMONIAN LAGS</h2>
      </div>
      <div class="background menu3"></div>
    </div>
    <!-- ARTISTS -->
    <div class="container" @click="routeTo('Artists', '/artists')" style="height: 100%;">
      <div class="overlay-button">
        <h1>ARTISTS</h1>
        <div style="width: 0.5em"></div>
        <h2>SONGS FROM THE<br>PRISON WITHOUT WALLS</h2>
      </div>
      <div class="background menu4"></div>
    </div>
    <!-- MAP -->
    <div v-if="false" class="container" @click="routeTo('Map', '/map')" style="height: 100%;">
      <div class="overlay-button">
        <h1>MAP</h1>
        <div style="width: 0.5em"></div>
        <h2>FIND STORIES, LAGS<br>AND SONGS NEAR YOU</h2>
      </div>
      <div class="background menu5"></div>
    </div>
    <!-- ABOUT -->
    <div class="container" @click="routeTo('About', '/about')" style="height: 100%; border: 0;">
      <div class="overlay-button">
        <h1>ABOUT</h1>
        <div style="width: 0.5em"></div>
        <h2>MORE ABOUT THE<br>VANDEMONIAN LAGS</h2>
      </div>
      <div class="background menu6"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuOverlayUnshackled",
  components: {},
  props: { hideMenu: Function },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    routeTo(name, path) {
      this.hideMenu();
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: filter 0.25s, height 1s;
  z-index: -1;
}
.overlay-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  h1 {
    font-size: clamp(2.85em, 10.5vw, 3.8em);
    margin-top: 10px;
    text-align: right;
  }
  h2 {
    font-size: clamp(1.15em, 4vw, 1.5em);
  }
}
.menu1 {background-image: url(@/assets/images/unshackled_menu_1.jpg);}
.menu2 {background-image: url(@/assets/images/unshackled_menu_2.jpg);}
.menu3 {background-image: url(@/assets/images/unshackled_menu_3.jpg);}
.menu4 {background-image: url(@/assets/images/unshackled_menu_4.jpg);}
.menu5 {background-image: url(@/assets/images/unshackled_menu_5.jpg);}
.menu6 {background-image: url(@/assets/images/unshackled_menu_6.jpg);}
</style>