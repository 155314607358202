<template>
  <div class="overlay">
    <!-- STORIES -->
    <div class="container" @click="routeTo('Stories', '/stories')" style="height: 100%;">
      <div class="overlay-button">
        <h1>STORIES</h1>
        <div style="width: 0.75em"></div>
        <h2>TRUE TALES FROM<br>THE NORFOLK PLAINS</h2>
      </div>
      <div class="background menu1"></div>
    </div>
    <!-- PEOPLE -->
    <div class="container" @click="routeTo('People', '/people')" style="height: 100%;">
      <div class="overlay-button">
        <h1>PEOPLE</h1>
        <div style="width: 0.75em"></div>
        <h2>CHARACTERS OF THE<br>GRASSY WOODLANDS</h2>
      </div>
      <div class="background menu2"></div>
    </div>
    <!-- LOCATION -->
    <div class="container" @click="routeTo('Locations', '/locations')" style="height: 100%;">
      <div class="overlay-button">
        <h1>LOCATION</h1>
        <div style="width: 0.75em"></div>
        <h2>PROPERTIES, OPENING<br>TIMES, AND EVENTS</h2>
      </div>
      <div class="background menu3"></div>
    </div>
    <!-- MAP -->
    <div class="container" @click="routeTo('Map', '/map')" style="height: 100%;">
      <div class="overlay-button">
        <h1>MAP</h1>
        <div style="width: 0.75em"></div>
        <h2>FIND THE STORIES<br>CLOSEST TO YOU</h2>
      </div>
      <div class="background menu4"></div>
    </div>
    <!-- ABOUT -->
    <div class="container" @click="routeTo('About', '/about')" style="height: 100%; border: 0;">
      <div class="overlay-button">
        <h1>ABOUT</h1>
        <div style="width: 0.75em"></div>
        <h2>LEARN ABOUT<br>REASSIGN</h2>
      </div>
      <div class="background menu5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuOverlay",
  components: {},
  props: { hideMenu: Function },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    routeTo(name, path) {
      this.hideMenu();
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: filter 0.25s, height 1s;
  z-index: -1;
}
.overlay-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  h1 {
    width: 50%;
    font-size: 3.5em;
    margin-top: 12px;
    text-align: right;
  }
  h2 {
    width: 50%;
    margin-top: 5px;
    font-size: 1.4em;
  }
}
.menu1 {background-image: url(@/assets/images/reassign_menu_1.jpg);}
.menu2 {background-image: url(@/assets/images/reassign_menu_2.jpg);}
.menu3 {background-image: url(@/assets/images/reassign_menu_3.jpg);}
.menu4 {background-image: url(@/assets/images/reassign_menu_4.jpg);}
.menu5 {background-image: url(@/assets/images/reassign_menu_5.jpg);}
</style>