import { createStore } from 'vuex'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default createStore({
  state: {
    site: "REASSIGN",
    content: null,
    loading: false,
    userLocation: null,
    searchVars: "",
    media: null,
    width: 500,
    height: 1000,
    firebasePath: "https://storage.googleapis.com/unshackled-roar.appspot.com/",
    storyFilter: [],
    peopleFilter: [],
    settings: null,
    helpMessage: null,
    pinColour: "#BB4422",
    contentPath: "content",
    mediaPath: "media",
    mediaUrl: "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=822774437",
    storiesUrl: "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=1667546858",
    peopleUrl: "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=414297789",
    artistUrl: null,
    songlinesUrl: null,
    locationUrl: "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=1603888594",
    markerUrl: "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=841866188"
  },
  getters: {
    getSite(state) {
      return state.site
    },
    getContentPath(state) {
      return state.contentPath
    },
    getMediaPath(state) {
      return state.mediaPath
    },
    getMediaUrl(state) {
      return state.mediaUrl
    },
    getLocationUrl(state) {
      return state.locationUrl
    },
    getStoriesUrl(state) {
      return state.storiesUrl
    },
    getSonglinesUrl(state) {
      return state.songlinesUrl
    },
    getPeopleUrl(state) {
      return state.peopleUrl
    },
    getArtistUrl(state) {
      return state.artistUrl
    },
    getLoactionUrl(state) {
      return state.loactionUrl
    },
    getMarkerUrl(state) {
      return state.markerUrl
    },
    getPinColour(state) {
      return state.pinColour
    },
    getHelpMessage(state) {
      return state.helpMessage
    },
    getSettings(state) {
      return state.settings
    },
    getStoryFilter(state) {
      return state.storyFilter
    },
    getPeopleFilter(state) {
      return state.peopleFilter
    },
    getFirebasePath(state) {
      return state.firebasePath
    },
    getWidth(state) {
      return state.width
    },
    getHeight(state) {
      return state.height
    },
    getSearchVars(state) {
      return state.searchVars
    },
    getLoading(state) {
      return state.loading
    },
    getContent(state) {
      return state.content
    },
    getUserLocation(state) {
      return state.userLocation
    },
    getMedia(state) {
      return state.media
    },
  },
  mutations: {
    setSite(state, payload) {
      state.site = payload
    },
    setContentPath(state, payload) {
      state.contentPath = payload
    },
    setMediaPath(state, payload) {
      state.mediaPath = payload
    },
    setMediaUrl(state, payload) {
      state.mediaUrl = payload
    },
    setStoriesUrl(state, payload) {
      state.storiesUrl = payload
    },
    setSonglinesUrl(state, payload) {
      state.songlinesUrl = payload
    },
    setPeopleUrl(state, payload) {
      state.peopleUrl = payload
    },
    setArtistUrl(state, payload) {
      state.artistUrl = payload
    },
    setLocationUrl(state, payload) {
      state.locationUrl = payload
    },
    setMarkerUrl(state, payload) {
      state.markerUrl = payload
    },
    setPinColour(state, payload) {
      state.pinColour = payload
    },
    setHelpMessage(state, payload) {
      state.helpMessage = payload
    },
    setSettings(state, payload) {
      state.settings = payload
    },
    setStoryFilter(state, payload) {
      state.storyFilter = payload
    },
    setPeopleFilter(state, payload) {
      console.log("SET PEOPLE", payload)
      state.peopleFilter = payload
    },
    setSize(state, payload) {
      state.width = payload.width
      state.height = payload.height
    },
    setSearchVars(state, payload) {
      state.searchVars = payload
    },
    setContent(state, payload) {
      state.content = payload
    },
    setMedia(state, payload) {
      console.log("MEDIA UPDATED", payload)
      state.media = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setUserLocation(state, payload) {
      state.userLocation = payload
    }
  },
  actions: {
    setSite({ commit }, site) {
      var r = document.querySelector('body');
      var rs = getComputedStyle(r);
      console.log("SET SITE", site)
      let pinColour
      commit("setSite", site)
      if (site == "Unshackled") {
        commit("setContentPath", "contentUnshackled")
        commit("setMediaPath", "mediaUnshackled")
        commit("setMediaUrl", "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=822774437")
        commit("setStoriesUrl", "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=1667546858")
        commit("setPeopleUrl", "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=414297789")
        commit("setArtistUrl", "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=1841731479")
        commit("setSonglinesUrl", "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=2086097502")
        commit("setLocationUrl", "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=1603888594")
        commit("setMarkerUrl", "https://docs.google.com/spreadsheets/d/1qUp2A7PsNdIzm-oswt5QIqojfUg5biDLRveu0F3l9LU/edit#gid=24640893")
        r.style.setProperty('--indigo', '#100F0B');
        r.style.setProperty('--transparent-indigo', '#100F0B00');
        r.style.setProperty('--grey', '#96874B');
        r.style.setProperty('--half-grey', '#96874B44');
        r.style.setProperty('--light-grey', '#FFEEBB');
        r.style.setProperty('--white', '#FFFFFF');
        r.style.setProperty('--image', 'url(../images/unshackled_texture.jpg)');
        commit("setPinColour", "#4477AA");
      } else if (site == "Reassign") {
        commit("setContentPath", "content")
        commit("setMediaPath", "media")
        commit("setMediaUrl", "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=822774437")
        commit("setStoriesUrl", "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=1667546858")
        commit("setPeopleUrl", "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=414297789")
        commit("setArtistUrl", null),
        commit("setSonglinesUrl", null),
        commit("setLocationUrl", "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=1603888594")
        commit("setMarkerUrl", "https://docs.google.com/spreadsheets/d/1rKILrhZ-BEp-qjGlJ75ZWa8rbFrfJtPakOtV0ZWm138/edit#gid=841866188")
        r.style.setProperty('--indigo', '#080F1A');
        r.style.setProperty('--transparent-indigo', '#080F1A00');
        r.style.setProperty('--grey', '#8899AA');
        r.style.setProperty('--half-grey', '#8899AA44');
        r.style.setProperty('--light-grey', '#BBC4D9');
        r.style.setProperty('--white', '#EEF0F8');
        r.style.setProperty('--image', 'url(../images/reassign_texture.jpg)');
        commit("setPinColour", "#BB4422");
      }
    },
    setStoryData({ commit, dispatch }, payload) {
      let cont = JSON.parse(JSON.stringify(payload))
      for (var c in cont) {
        console.log("setData")
        dispatch("updateStoryItem", cont[c])
      }
      // commit('setMedia',cont)
    },
    updateStoryItem({ commit, getters }, item) {
      console.log("UPDATE", item, " to ", getters.getContentPath)
      let cRef = firebase.firestore().collection(getters.getContentPath)
      cRef.doc(item.id).set(item);
    },
    updatePeopleItem({ commit, getters }, item) {
      console.log("UPDATE", item)
      let cRef = firebase.firestore().collection(getters.getContentPath)
      cRef.doc(item.id).set(item);
    },
    setData({ commit, dispatch }, payload) {
      let cont = JSON.parse(JSON.stringify(payload))
      for (var c in cont) {
        console.log("setData")
        dispatch("updateMediaItem", cont[c])
      }
      // commit('setMedia',cont)
    },
    updateMediaItem({ commit, getters }, item) {
      console.log("UPDATE", item)
      let cRef = firebase.firestore().collection(getters.getMediaPath)
      cRef.doc(item.id).set(item);
    },
    updateARMarker({ commit, getters }, payload) {
      console.log("UPDATE", payload)
      let cRef = firebase.firestore().collection(getters.getMediaPath)
      cRef.doc(payload.id).update({ markers: payload.markers });
    },
    getContent({ commit, getters }) {
      commit('setLoading', true)
      console.log("GET CONTENT", getters.getContentPath)
      let cRef = firebase.firestore().collection(getters.getContentPath)
      let cont = []
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            let data = doc.data()
            cont.push(data)
          })
          commit('setContent', cont)
        } else {
          console.log("QUERY NO DATA")
        }
        commit('setLoading', false)
      }).catch(err => {
        console.log('Error logged', err);
        commit('setLoading', false)
      })
    },
    getMedia({ commit, getters }, payload) {
      commit('setLoading', true)
      console.log("GET MEDIA", payload)
      let cRef = firebase.firestore().collection(getters.getMediaPath).doc(payload)
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          console.log("MEDIA IS XX", querySnapshot.data())
          commit("setMedia", querySnapshot.data())
        } else {
          console.log("QUERY NO DATA")
        }
        commit('setLoading', false)
      }).catch(err => {
        console.log('Error logged', err);
        commit('setLoading', false)
      })
    },
    getSettings({ commit }) {
      commit('setLoading', true)
      let cRef = firebase.firestore().collection("settings")
      let settings = {}
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          console.log("SETTINGS", querySnapshot.doc)
          querySnapshot.forEach(doc => {
            console.log("SETTINGS", doc.data())
            if (doc.data()) {
              settings = doc.data()
            }
          })
          console.log("SETTINGS -- ", settings)
          commit('setSettings', settings)
        } else {
          console.log("QUERY NO DATA")
        }
        commit('setLoading', false)
      }).catch(err => {
        console.log('Error logged', err);
        commit('setLoading', false)
      })
    }
  }
})