<template>
  <div class="pageContent">
  <div class="homepage exhibition"  @click="launch('https://exhibition.unshackled.net.au/')">
    <img class="logo" src="../assets/images/homepage/exhibition_logo.png">
  </div>
  <div class="homepage unshackled" @click="launch('https://unshackled.net.au/lags')">
    <img class="logo" src="../assets/images/homepage/unshackled_logo.png">
  </div>
  <!-- <div class="homepage memorial" @click="launch('https://exhibition.unshackled.net.au/')">
    <img class="logo" src="../assets/images/homepage/memorial_logo.png">
  </div> -->
</div>
</template>

<script>
export default {
  name: "Homepage",
  methods:{
    launch(address){
      window.open(address,"_self")
    }
  }
};
</script>

<style lang="scss" scoped>
.pageContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap:10vh;
}
.homepage {
  display: flex;
  justify-content: space-around;
  transition: 0.5s;
}
.exhibition {
  background: var(--charcoal) url(@/assets/images/homepage/exhibition_image.png) no-repeat center;
  background-size: 100%;
}
.unshackled {
  background: var(--charcoal) url(@/assets/images/homepage/unshackled_image.png) no-repeat center;
  background-size: 100%;
}
.memorial {
  background: var(--charcoal) url(@/assets/images/homepage/memorial_image.png) no-repeat center;
  background-size: 100%;
}
.logo {
  width: clamp(18em, 90vw, 30em);
  margin-bottom: 0.5em;
  filter: drop-shadow(0 0 2em #000000) drop-shadow(0 0 1em #000000);
  transition: 0.5s;
}
.homepage:hover {
  filter: brightness(1.25) contrast(1.075);
  background-size: 102%;
  .logo {
    transform: scale(1.1);
  }
}
</style>