export default {
  data() {
    return {
      videoIcon: "\ue800",
      musicIcon: "\ue801",
      podcastIcon: "\ue802", podIcon: "\ue802",
      viewpointIcon: "\ue803",
      propertyIcon: "\ue804",
      foodIcon: "\ue805",
      websiteIcon: "\ue806",
      directionIcon: "\ue807", imageIcon: "\ue807",
      callIcon: "\ue808",
      locationIcon: "\ue809",
      searchIcon: "\ue80a", examineIcon: "\ue80a",
      zoomInIcon: "\ue80b", townIcon: "\ue80b",
      zoomOutIcon: "\ue80c",
      centerIcon: "\ue80d",
      menuIcon: "\ue80e", assigneesIcon: "\ue80e",
      crossIcon: "\ue80f",
      warningIcon: "\ue810",
      peopleIcon: "\ue811",
      objectIcon: "\ue812",
      droneIcon: "\ue813",
      windowIcon: "\ue814",
      storyIcon: "\ue815",
      backIcon: "\ue816",
      helpIcon: "\ue817",
      arIcon: "\ue818",
      unescoIcon: "\ue819",
      trustIcon: "\ue81a",
      townIcon: "\ue81b",
      modelIcon: "\ue81c",
      interviewIcon: "\ue81d",

      videoIconText: "&#xe800;",
      musicIconText: "&#xe801;",
      podcastIconText: "&#xe802;", podIconText: "&#xe802;",
      viewpointIconText: "&#xe803;",
      propertyIconText: "&#xe804",
      foodIconText: "&#xe805",
      websiteIconText: "&#xe806",
      directionIconText: "&#xe807", imageIconText: "&#xe807",
      callIconText: "&#xe808",
      locationIconText: "&#xe809",
      searchIconText: "&#xe80a", examineIconText: "&#xe80a",
      zoomInIconText: "&#xe80b",
      zoomOutIconText: "&#xe80c",
      centerIconText: "&#xe80d",
      menuIconText: "&#xe80e", assigneesIconText: "&#xe80e",
      crossIconText: "&#xe80f", storyIconText: "&#xe80f",
      warningIconText: "&#xe810",
      peopleIconText: "&#xe811",
      objectIconText: "&#xe812",
      droneIconText: "&#xe813",
      windowIconText: "&#xe814",
      storyIconText: "&#xe815",
      backIconText: "&#xe816",
      helpIconText: "&#xe817",
      arIconText: "&#xe818",
      unescoIconText: "&#xe819",
      trustIconText: "&#xe81a",
      townIconText: "&#xe81b",
      modelIconText: "&#xe81c",
      interviewIconText: "&#xe81d",
    };
  },
  mounted() { },
  computed: {},
  methods: {
    icon(val) {
      let icon = this[val + "Icon"] || " "
      if (icon == " ") {
        console.log("Missing", val, "Icon")
      }
      switch (val) {
        case "property":
          icon = this.propertyIcon
          break;
        case "story":
          icon = this.storyIcon
          break;
      }
      return icon
    },
    iconText(val) {
      let icon = " "
      //if(this[val + "IconText"]){
      //  icon= this[val + "IconText"] 
      //  console.log("ICON ",val)
      //}
      switch (val) {
        case "ar":
          icon = this.arIconText
          break;
        case "pod":
          icon = this.podIconText
          break;
        case "music":
          icon = this.musicIconText
          break;
        case "video":
          icon = this.videoIconText
          break;
        case "interview":
          icon = this.interviewIconText
          break;
        case "song":
          icon = this.musicIconText
          break;
        case "connection":
          icon = this.peopleIconText
          break;
        case "person":
          icon = this.peopleIconText
          break;
        case "podcast":
          icon = this.podcastIconText
          break;
        case "viewpoint":
          icon = this.viewpointIconText
          break;
        case "property":
          icon = this.propertyIconText
          break;
        case "food":
          icon = this.foodIconText
          break;
        case "website":
          icon = this.websiteIconText
          break;
        case "artist":
          icon = this.peopleIconText
          break;
        case "videoAR":
          icon = this.peopleIconText
          break;
        case "objectAR":
          icon = this.objectIconText
          break;
        case "imageAR":
          icon = this.imageIconText
          break;
        case "droneAR":
          icon = this.droneIconText
          break;
        case "drone":
          icon = this.droneIconText
          break;
        case "windowAR":
          icon = this.windowIconText
          break;
        case "examine":
          icon = this.searchIconText
          break;
      }
      return icon
    },
    countryIcon(place) {
      return place.split(" ").pop().toLowerCase()
    },
    nameLookup(val) {
      let icon = val
      switch (val) {
        case "videoAR":
          icon = "Video AR"
          break;
        case "objectAR":
          icon = "Object"
          break;
        case "imageAR":
          icon = "Image AR"
          break;
        case "droneAR":
          icon = "Eye in the Sky"
          break;
        case "drone":
          icon = "Eye in the Sky"
          break;
        case "windowAR":
          icon = "Inside Out"
          break;
        case "window":
          icon = "Inside Out"
          break;
      }
      return icon
    }
  },
  beforeDestroy: function () { },
  watch: {}
}